export class DevSettings {
  get hasValues(): boolean {
    return !!this.getValue('devMode');
  }

  get env(): string { return this.getValue('env'); }
  set env(value: string) { this.setValue('env', value); }

  get apiOrigin(): string { return this.getValue('apiOrigin'); }
  set apiOrigin(value: string) { this.setValue('apiOrigin', value); }

  get b2cInstance(): string { return this.getValue('b2cInstance'); }
  set b2cInstance(value: string) { this.setValue('b2cInstance', value); }

  get loggingLevel(): number {
    const level = parseInt(this.getValue('loggingLevel'), 10);
    return isNaN(level) ? 8 : level;
  }
  set loggingLevel(value: number) { this.setValue('loggingLevel', value >= 0 ? value.toString() : ''); }

  get disableApiCache(): boolean { return JSON.parse(this.getValue('disableApiCache') || 'false'); }
  set disableApiCache(value: boolean) { this.setValue('disableApiCache', JSON.stringify(value) )}

  get enableIndexedDbCache(): boolean { return JSON.parse(this.getValue('enableIndexedDbCache') || 'false'); }
  set enableIndexedDbCache(value: boolean) { this.setValue('enableIndexedDbCache', JSON.stringify(value) )}

  private getValue(propName: string): string {
    return window.sessionStorage[propName] || '';
  }

  private setValue(propName: string, value: string): void {
    if (value && value.length) {
      window.sessionStorage[propName] = value;
      window.sessionStorage.devMode = true;
    } else {
      window.sessionStorage.removeItem(propName);
      if (!this.env && !this.apiOrigin && !this.b2cInstance) {
        window.sessionStorage.removeItem('devMode');
      }
    }
  }

  public reset(): void {
    this.env = null;
    this.apiOrigin = null;
    this.b2cInstance = null;
  }
}
